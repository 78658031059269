import React, { Suspense } from "react";
import { Routes as Switch, Route } from "react-router-dom";
import { Typography } from "@mui/material";
import Suggest from "../pages/Suggest";
import { generators, newGenerators } from "generators";
import { OldGenerator, Generator } from "components";
import { Fallback } from "pages";
import RatGenerator from "generators/rat/RatGenerator";
import { OldSaved } from "components/oldGenerator";
import { RatButton } from "misc/fun";
import { Saved } from "components/generator";
import Formatter from "pages/Formatter";
import SubmitTomestone from "pages/SubmitTomestone";
import Kenning from "pages/kenning/Kenning";
import { Bargains } from "generators/bargains";

const Routes: React.FC = function Routes() {
  const generatorsArray = Object.entries(generators);

  const generatorPages: JSX.Element[] = [];

  generatorsArray.forEach(([key, value], i) => {
    const actualPage = (
      <Route
        path={key}
        element={<OldGenerator generate={value} key={key} category={key} />}
        key={key}
      />
    );
    const savedPage = (
      <Route
        path={`${key}/saved`}
        element={<OldSaved key={`${key}-saved`} category={key} />}
        key={`${key}-saved`}
      />
    );

    generatorPages.push(actualPage);
    generatorPages.push(savedPage);
  });

  const newGeneratorsArray = Object.entries(newGenerators);

  const newGeneratorPages: JSX.Element[] = [];

  newGeneratorsArray.forEach(([key, value]) => {
    const { SavedRow, ...rest } = value;
    const actualPage = (
      <Route
        path={key}
        element={<Generator {...(rest as any)} key={key} category={key} />}
        key={key}
      />
    );
    const savedPage = (
      <Route
        path={`${key}/saved`}
        element={
          <Saved
            SavedRow={SavedRow as any}
            key={`${key}-saved`}
            category={key}
          />
        }
        key={`${key}-saved`}
      />
    );

    newGeneratorPages.push(actualPage);
    generatorPages.push(savedPage);
  });

  const bargainsPage = (
    <Route path="/bargains" element={<Bargains />} key="bargains" />
  );
  generatorPages.push(bargainsPage);

  return (
    <Suspense fallback={<Typography>Loading...</Typography>}>
      <Switch>
        <Route
          path="/"
          element={
            <>
              <Typography variant="h4">random generator</Typography>
              <RatButton />
            </>
          }
        />
        {newGeneratorPages}
        {generatorPages}
        <Route path="rat/v2" element={<RatGenerator />} />
        <Route path="*" element={<Fallback />} />
        <Route path="/suggest" element={<Suggest />} />
        <Route path="/formatter" element={<Formatter />} />
        <Route path="/tomestone" element={<SubmitTomestone />} />
        <Route path="/kenning" element={<Kenning />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
